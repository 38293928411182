/* You can add global styles to this file, and also import other style files */

/* Tailwind CSS */

@layer tailwind-base, primeng;

@import "tailwindcss/base.css" layer(tailwind-base);
@tailwind components;
@tailwind utilities;

@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "prime-fix";
@import "tailwind";
