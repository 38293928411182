body a {
  color: unset;
  text-decoration: unset;
  /* all: unset */
}
body a:hover {
  color: unset;
  /* all: unset */
}
body a:active {
  color: unset;
  /* all: unset */
}

/* ToDo CSS in Component? */
.ui-steps .ui-steps-item .ui-menuitem-link {
  padding: 0;
  padding-left: 1em;
  padding-right: 1em;
}

body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: 100%;
}

body .ui-listbox .ui-listbox-header {
  border-bottom: 0;
}

body .ui-listbox {
  padding: 0.571em 1em;
}

.ui-tree-container {
  overflow: auto;
  max-height: 293px;
}

body .ui-tree {
  border: 1px solid #a6a6a6;
}

body .ui-tabview .ui-tabview-panels {
  padding: 0;
  border: 0;
}

body .ui-listbox .ui-listbox-header {
  padding: 0.4em;
}

body .ui-tabview {
  padding: 0;
}

.ui-tree {
  width: auto;
}

.ui-listbox {
  width: auto;
}

p-dropdown.ng-invalid {
  border: 0px !important;
}

p-dropdown.ng-invalid.ng-touched div.ui-dropdown {
  border: 1px solid #a80000 !important;
}

toast {
  z-index: 7000;
}

.p-dropdown {
  width: 333px !important;
  height: 40px !important;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
