/*Navbar*/
.Kategorie {
  @apply mt-2 block border-r-2 border-blue-800 shadow bg-blue-500 font-semibold text-2xl;
}

.Punkt {
  @apply inline-block w-full pl-0 pl-4 transition duration-150 uppercase font-normal;
}

.Unterpunkt {
  @apply inline-block w-full pt-0 pl-6 pr-1 m-0 text-lg transition duration-150 uppercase font-light leading-9;
}

/*Formulare*/
.Formular {
  @apply bg-white shadow-md rounded p-8 mb-4;
  max-width: 35rem;
}

.Textfeld {
  @apply shadow appearance-none border rounded py-2 px-3 text-gray-700 w-auto mb-1 leading-tight transition duration-150;
  width: 333px !important;
}

.Label {
  @apply inline-block text-gray-700 text-sm font-bold mr-5;
  width: 140px;
  text-align: right;
}

.Btn {
  @apply bg-blue-500 text-white font-bold py-2 px-4 rounded transition duration-150;
}

.Ueberschrift {
  @apply text-2xl border-b-4 pb-3 font-thin text-gray-600 uppercase;
}

.Nachricht {
  @apply border font-medium leading-tight max-w-xs px-4 py-2;
}

.rot {
  @apply border-red-400 bg-red-200 text-red-600;
}

.gruen {
  @apply border-green-400 bg-green-200 text-green-600;
}

.router-link-exact-active {
  background-color: #2c5282;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.hoverbox {
  @apply w-64 h-64 transition duration-500 ease-in-out bg-blue-200 transform px-0 py-12 text-center text-xl text-gray-800 font-light break-normal;
}

.hoverbox_klein {
  @apply w-32 h-32 transition duration-500 ease-in-out bg-blue-200 transform px-0 py-6 text-center text-sm text-gray-800 font-thin break-normal mb-8;
}

.avatar {
  border-radius: 9999px;
  text-align: center;
  font-weight: 700;
  display: block;
  width: 32px;
}

.avatar:after {
  border-radius: 9999px;
  text-align: center;
  font-weight: 700;
  display: block;
  width: 32px;
  content: "";
}

.verzeichnis-baum-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 0;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom: 1px solid #eaeaea;
}

.verzeichnis-baum {
  overflow: auto;
  padding: 16px;
}

.icon {
  @apply w-8 inline-block pb-1;
}

@media only screen and (max-width: 640px) {
  .max-h-resp {
    max-height: 10rem;
  }
}

@media only screen and (max-width: 768px) {
  .max-h-resp {
    max-height: 15rem;
  }
}

@media only screen and (max-width: 1024px) {
  .max-h-resp {
    max-height: 25rem;
  }
}

@media only screen and (max-width: 1280px) {
  .max-h-resp {
    max-height: 35rem;
  }
}

/* TODO: Brauchen wir das noch? */
/* .p-dropdown-items-wrapper {
    @apply bg-white border-gray-300 border shadow-lg;
}

.p-dropdown:focus {
    @apply shadow-outline bg-white;
}

.p-dropdown-item {
    @apply m-0;
}

.p-dropdown-item:hover {
    @apply bg-gray-100;
}

.p-dropdown-item:active {
    @apply bg-gray-300;
} */

.Textfeld label {
  @apply p-0;
}

.ui-dropdown {
  @apply shadow appearance-none border rounded py-2 px-3 text-gray-700 w-auto mb-1 leading-tight transition duration-150;
  width: 333px !important;
  height: 38px;
}

.ui-button {
  @apply rounded;
}

@layer utilities {
  .Status {
    width: 450px;
  }
  .ml-einsechstel {
    margin-left: 16.66666667%;
  }
}
